import {
  riskCriticalities,
  getRiskScoreCriticality,
} from '../../constants/criticalities'

export default {
  riskregister: {
    error: null,

    statuses: [],
    decisions: [],
    categories: {}, // [registerId]: [ array of categories, ... ]
    riskRegisters: [],
    allRiskRegisterOwners: [],
    allScopeGroupsInRegister: [],
    allTagsInRegister: [],

    riskScores: [
      {
        value: (v) => v === 0,
        header: 'Not Set',
        variant: 'gray',
        policy_text: 'Score has not been set',
      },
      {
        value: (v) => v > 0 && v < 4,
        header: 'Low Risk',
        variant: 'success',
        policy_text: 'Manage by routine procedures',
      },
      {
        value: (v) => v >= 4 && v <= 7,
        header: 'Medium Risk',
        variant: 'info',
        policy_text: 'Specify management responsibility',
      },
      {
        value: (v) => v >= 8 && v < 15,
        header: 'High Risk',
        variant: 'warning',
        policy_text: 'Needs Senior Management attention',
      },
      {
        value: (v) => v >= 15,
        header: 'Extreme Risk',
        variant: 'danger',
        policy_text: 'Detailed Action plan required',
      },
    ],
    acceptableRisk: [],
    impactScores: [],
    likelihoodScores: [],
    costScores: [],
    effortScores: [],

    currentRegister: {},
    currentRegisterAssessmentMappings: [],

    currentItem: {
      record: {},
      history: [],
      comments: [],
      files: [],
      owners: [],
      tags: [],
      changeLogs: [],
      pmTimeline: [],
      jiraMappings: [],
      calendarEvents: [],
      teamVendors: [],
      inventoryItems: [],
      controls: [],
      projectTasks: [],
      myControls: [],
      scopeGroups: [],
    },

    sorting: {},
    riskSearch: null,
    itemFilters: {
      assessment: [],
      category: [],
      dates: [],
      decision: [],
      owner: [],
      register: [],
      riskScore: [],
      scope: [],
      status: [],
      tag: [],
      startDate: null,
      endDate: null,
      completeDate: null,
      identifiedDate: null,
      search: null,
    },
    dashboardFilters: {
      assessment: [],
      category: [],
      decision: [],
      owner: [],
      register: [],
      riskScore: [],
      scope: [],
      status: [],
      tag: [],
      // "open", "complete", or null for risk count cards
      limitByDateType: null,
      risksOpenedDateRange: '3M',
      risksMarkedCompleteDateRange: '3M',
      riskHistoryDateRange: '3M',
    },
    dashboardLoading: {
      risksTotalCount: false,
      risksOpenedCount: false,
      risksMarkedCompleteCount: false,

      riskByOwner: false,
      riskByX: false,

      riskCountsByTreatmentDecision: false,
      riskAging: false,

      topRisks: false,
      riskHistory: false,
    },

    dashboardData: {
      risksTotalCount: 0,
      risksOpenedCount: 0,
      risksMarkedCompleteCount: 0,

      byXCounts: [],
      ownerCounts: [],

      riskCountsByTreatmentDecision: [],
      riskAging: [],

      topRisks: [],
      riskHistory: [],
    },

    dashboardDateRangeOptions: [
      { text: '30 Days', value: '1M' },
      { text: '60 Days', value: '2M' },
      { text: '90 Days', value: '3M' },
      { text: '6 Months', value: '6M' },
      { text: 'This Quarter', value: '1Q' },
      // currently the filter has no start date, so "Last Quarter" isn't doable yet.
      // { text: 'Last Quarter', value: '2Q' },
      { text: 'Year to Date', value: 'ytd' },
    ],

    dashboardCountCardTypes: [
      'total-risks',
      'risks-opened',
      'risks-marked-complete',
    ],

    dashboardRiskByXGroupBy: { text: 'Category', value: 'category' },

    items: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 1e5,
      totalCount: 0,
    },

    frontendPaginatedData: {
      currentPage: 1,
      perPage: 10,
    },

    getRowNumber(index) {
      return (
        this.frontendPaginatedData.perPage *
          (this.frontendPaginatedData.currentPage - 1) +
        (index + 1)
      )
    },

    getSingleDatumVariant(score) {
      if (score === null) return 'gray'

      if (score <= 1) return 'success'

      if (score <= 2) return 'minimal'

      if (score <= 3) return 'info'

      if (score <= 4) return 'warning'

      if (score > 4) return 'danger'

      return 'gray'
    },

    getRiskScoreVariant(score) {
      if (score === null) return 'gray'

      if (score <= 3) return 'success'

      if (score <= 6) return 'info'

      if (score <= 12) return 'warning'

      if (score > 12) return 'danger'

      return 'gray'
    },

    riskRegisterRiskScoreInfo(registerItems) {
      let sortedRegisterItems = {
        ex: [],
        high: [],
        med: [],
        low: [],
        not_set: [],
      }
      const { ex, high, med, low, not_set } = registerItems.reduce(
        (prev, curr) => {
          const criticality = getRiskScoreCriticality(curr.risk_score)
          switch (criticality) {
            case riskCriticalities.LOW.val:
              prev.low.push(curr)
              break
            case riskCriticalities.MEDIUM.val:
              prev.med.push(curr)
              break
            case riskCriticalities.HIGH.val:
              prev.high.push(curr)
              break
            case riskCriticalities.EXTREME.val:
              prev.ex.push(curr)
              break
            case null:
              prev.not_set.push(curr)
              break
          }
          return prev
        },
        sortedRegisterItems
      )

      return [
        {
          key: riskCriticalities.NOT_SET.text,
          value: not_set.length,
          subvalue: `${not_set.reduce(
            (t, i) => t + i.hours_to_complete,
            0
          )} hours`,
          variant: riskCriticalities.NOT_SET.variant,
        },
        {
          key: riskCriticalities.LOW.text,
          value: low.length,
          subvalue: `${low.reduce((t, i) => t + i.hours_to_complete, 0)} hours`,
          variant: riskCriticalities.LOW.variant,
        },
        {
          key: riskCriticalities.MEDIUM.text,
          value: med.length,
          subvalue: `${med.reduce((t, i) => t + i.hours_to_complete, 0)} hours`,
          variant: riskCriticalities.MEDIUM.variant,
        },
        {
          key: riskCriticalities.HIGH.text,
          value: high.length,
          subvalue: `${high.reduce(
            (t, i) => t + i.hours_to_complete,
            0
          )} hours`,
          variant: riskCriticalities.HIGH.variant,
        },
        {
          key: riskCriticalities.EXTREME.text,
          value: ex.length,
          subvalue: `${ex.reduce((t, i) => t + i.hours_to_complete, 0)} hours`,
          variant: riskCriticalities.EXTREME.variant,
        },
      ]
    },
  },
}
