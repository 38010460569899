// the name MUST match the expected filter name on the backend to work
const myControlsFilters = [
  {
    name: 'framework',
    label: 'Framework',
    component: 'DashboardFrameworkFilter',
  },
  {
    name: 'domain',
    label: 'Domain',
    component: 'DashboardDomainFilter',
  },
  {
    name: 'scope',
    label: 'Scope',
    component: 'DashboardScopeFilter',
  },
  {
    name: 'owner',
    label: 'Owner',
    component: 'DashboardOwnerFilter',
  },
  {
    name: 'tag',
    label: 'Tag',
    component: 'DashboardTagsFilter',
  },
  {
    name: 'applicability',
    label: 'Applicable',
    component: 'DashboardApplicabilityFilter',
  },
]

const projectsFilters = [
  {
    name: 'section',
    label: 'Section',
  },
  {
    name: 'user',
    label: 'User',
  },
  {
    name: 'criticality',
    label: 'Criticality',
  },
  {
    name: 'status',
    label: 'Status',
  },
  {
    name: 'tags',
    label: 'Tags',
  },
  {
    name: 'project',
    label: 'Project',
  },
  {
    name: 'scope',
    label: 'Scope',
  },
]

const riskRegisterFilters = [
  {
    name: 'register',
    label: 'Risk Register',
    component: 'DashboardRiskRegisterFilter',
  },
  {
    name: 'category',
    label: 'Category',
    component: 'DashboardRiskCategoryFilter',
  },
  {
    name: 'user',
    label: 'User',
    component: 'DashboardRiskUserFilter',
  },
  {
    name: 'decision',
    label: 'Decision',
    component: 'DashboardRiskDecisionFilter',
  },
  {
    name: 'status',
    label: 'Status',
    component: 'DashboardRiskStatusFilter',
  },
  {
    name: 'tag',
    label: 'Tag',
    component: 'DashboardRiskTagFilter',
  },
  {
    name: 'scope',
    label: 'Scope',
    component: 'DashboardRiskScopeFilter',
  },
  {
    name: 'riskScore',
    label: 'Score',
    component: 'DashboardRiskScoreFilter',
  },
]

// key is the `val` from the widget
// value is the allowed filters, either the defined ones above, or a custom list
export default {
  OperationalStatus: myControlsFilters,

  // Risk Register Widgets
  RegisterListView: [
    {
      name: 'register',
      label: 'Risk Register',
      component: 'DashboardRiskRegisterFilter',
    },
    {
      name: 'riskScore',
      label: 'Score',
      component: 'DashboardRiskScoreFilter',
    },
  ],
  RiskAging: riskRegisterFilters,
  RiskByOwner: riskRegisterFilters,
  RiskRegisterListSummary: riskRegisterFilters,
  RiskByX: riskRegisterFilters,
  RiskHistory: riskRegisterFilters,
  RisksMarkedCompleteCount: riskRegisterFilters.filter(
    (filter) => filter.name !== 'status'
  ),
  RisksOpenedCount: riskRegisterFilters.filter(
    (filter) => filter.name !== 'status'
  ),
  RiskTreatment: riskRegisterFilters.filter(
    (filter) => filter.name !== 'decision'
  ),
  TopRisks: riskRegisterFilters,
  TotalRisksCount: riskRegisterFilters.filter(
    (filter) => filter.name !== 'status'
  ),
  EvidenceStatus: myControlsFilters,
  OperationalStatusByUser: myControlsFilters,
  EvidenceStatusByUser: myControlsFilters,
  OperationalStatusOverTime: myControlsFilters,
  DomainImplementationStatus: myControlsFilters,
}
