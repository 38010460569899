export default {
  SET_ASSESSMENTS_ALL_STATUSES(
    state,
    [allStatusAry, allControlStatusAry, allUserStatusAry, projectStatuses]
  ) {
    state.assessments.allRequestStatuses = allStatusAry
    state.assessments.allControlStatuses = allControlStatusAry
    state.assessments.updatableRequestStatuses = allUserStatusAry
    state.assessments.projectStatuses = {
      ...state.assessments.projectStatuses,
      ...projectStatuses,
    }
  },

  SET_ASSESSMENT_PROJECTS(state, projects) {
    state.assessments.projects = projects
  },

  SET_ASSESSMENT_ADDITIONAL_TEMPLATES(state, templates) {
    state.assessments.additionalTemplates = templates
  },

  SET_ASSESSMENT_PROJECT_HIERARCHY(state, hierarchy) {
    state.assessments.hierarchy = hierarchy
  },

  SET_ASSESSMENTS_ENGAGEMENT_TYPES(state, types) {
    state.assessments.engagementTypes = types
  },

  SET_ASSESSMENTS_GAP_TYPES(state, gapTypes) {
    state.assessments.projectGapTypes = gapTypes
  },

  SET_KEY_CONTACT_ROLES(state, { projectId, contacts }) {
    state.assessments.keyContactRoles[projectId] = contacts
  },

  SET_DASHBOARD_DATA(state, data) {
    state.assessments.dashboardData = data
  },

  SET_CURRENT_PROJECT(state, project) {
    state.assessments.currentProject = project
  },

  SET_CURRENT_PROJECT_CHARTING(
    state,
    { gapSummary, gapSummaryByCategory, hitrustPrisma, hitrustPrismaRef }
  ) {
    state.assessments.charting.gapSummary = gapSummary
    state.assessments.charting.gapSummaryByCategory = gapSummaryByCategory
    state.assessments.charting.hitrustPrisma = hitrustPrisma
    state.assessments.charting.hitrustPrismaRef = hitrustPrismaRef
  },

  SET_CURRENT_PROJECT_CATEGORIES(state, categoriesAndRequests) {
    state.assessments.currentProjectCategories = categoriesAndRequests
  },

  CLEAR_CURRENT_PROJECT_FILTERS(state, tab) {
    state.assessments.currentProjectFilters[tab] = {
      ...state.assessments.defaultProjectFilters[tab],
    }
  },

  SET_FORMAL_FINDINGS_FILTER(state, { key, val }) {
    state.assessments.formalFindingsFilters[key] = val
  },

  CLEAR_FORMAL_FINDINGS_FILTERS(state) {
    state.assessments.formalFindingsFilters = {
      includedChildren: [],
      publishedStatus: [],
    }
  },

  SET_CURRENT_PROJECT_FILTER(state, { tab, key, val }) {
    state.assessments.currentProjectFilters[tab][key] = val
  },

  SET_CURRENT_PROJECT_FILTERS(state, { tab, filters }) {
    if (!filters || Object.keys(filters).length === 0) {
      state.assessments.currentProjectFilters[tab] = {
        ...state.assessments.defaultProjectFilters[tab],
      }
    } else {
      state.assessments.currentProjectFilters[tab] = filters
    }
  },

  SET_CURRENT_PROJECT_CRITERIA(state, criteria) {
    state.assessments.currentProjectCriteria = criteria
  },

  SET_CURRENT_PROJECT_CONTROL_ONLY(state, control) {
    state.assessments.currentControl = control
  },

  SET_CURRENT_PROJECT_CONTROL_TESTINFO_ONLY(state, testInfo) {
    state.assessments.currentControlTestInfo = testInfo
  },

  SET_CURRENT_PROJECT_CONTROL(
    state,
    {
      control,
      assignments,
      // categories,
      duplicates,
      files,
      reviewComments,
      questions,
      requests,
      testInfo,
      tags,
      automations,
    }
  ) {
    state.assessments.currentControl = control
    state.assessments.currentControlAssignments = assignments
    // currentControlCategories: []
    state.assessments.currentControlDuplicates = duplicates
    state.assessments.currentControlFiles = files
    state.assessments.currentControlQuestions = questions
    state.assessments.currentControlRequests = requests
    state.assessments.currentControlReviewComments = reviewComments
    state.assessments.currentControlTags = tags
    state.assessments.currentControlTestInfo = testInfo
    state.assessments.currentRequestAutomations = automations
  },

  SET_CURRENT_PROJECT_REQUEST_ONLY(state, request) {
    state.assessments.currentRequest = request
  },

  SET_CURRENT_PROJECT_REQUEST_COMMENTS(state, comments) {
    state.assessments.currentRequestComments = comments
  },

  SET_CURRENT_PROJECT_REQUEST(
    state,
    {
      request,
      assignments,
      uploadUsers,
      // categories,
      changeLog,
      comments,
      controls,
      attachments,
      tags,
      calendarEvents,
      automations,
      evidenceObjects,
    }
  ) {
    state.assessments.currentRequest = request
    state.assessments.currentRequestProcedures = request.request_procedures
    state.assessments.currentRequestAssignments = assignments
    state.assessments.currentRequestUploadUsers = uploadUsers
    // state.assessments.currentRequestCategories = categories
    state.assessments.currentRequestChangeLogs = changeLog
    state.assessments.currentRequestComments = comments
    state.assessments.currentRequestControls = controls
    state.assessments.currentRequestAttachments = attachments
    state.assessments.currentRequestTags = tags
    state.assessments.currentRequestCalendarEvents = calendarEvents
    state.assessments.currentRequestAutomations = automations
    state.assessments.currentRequestEvidenceObjects = evidenceObjects
  },

  SET_CURRENT_PROJECT_TEAM(state, teamMembers) {
    state.assessments.currentProjectTeam = teamMembers
  },

  SET_CURRENT_PROJECT_AUTOMATIONS(state, automations) {
    state.assessments.currentProjectAutomations = automations
  },

  SET_CURRENT_FORMAL_FINDINGS_STATUSES(state, statuses) {
    state.assessments.currentFormalFindingsStatuses = statuses
  },

  SET_CURRENT_FORMAL_FINDINGS(state, findings) {
    state.assessments.currentFormalFindings = findings
  },

  RESET_NEW_FORMAL_FINDING_TO_TOP(state, newFindingIndex) {
    const newFinding = {
      ...state.assessments.currentFormalFindings[newFindingIndex],
      isEditing: true,
    }
    state.assessments.currentFormalFindings.splice(newFindingIndex, 1)
    state.assessments.currentFormalFindings.splice(0, 0, newFinding)
  },

  ADD_CONTROL_TO_CURRENT_FORMAL_FINDINGS(state, { id, control }) {
    const index = state.assessments.currentFormalFindings.findIndex(
      (f) => f.id == id
    )
    state.assessments.currentFormalFindings[index].controls =
      state.assessments.currentFormalFindings[index].controls || []
    state.assessments.currentFormalFindings[index].controls.push(control)

    const uniqueControlIds = [
      ...new Set(
        state.assessments.currentFormalFindings[index].controls.map(
          (c) => `${c.id}|${c.dup_id || 0}`
        )
      ),
    ]
    state.assessments.currentFormalFindings[index].controls =
      uniqueControlIds.map((idAndDup) => {
        return state.assessments.currentFormalFindings[index].controls.find(
          (c) => `${c.id}|${c.dup_id || 0}` === idAndDup
        )
      })
  },

  SET_CURRENT_MAPPINGS_COUNT(state, count) {
    state.assessments.currentProjectRequestMappingsCount = count
  },

  SET_FRAMEWORK_KPIS(state, kpis) {
    const finalKpis = state.assessments.frameworkKpis.map((f) => {
      const exists = kpis.find((k) => {
        return k.engagement_type == f.engagement_type
      })

      if (exists) return exists

      return f
    })
    state.assessments.frameworkKpis = finalKpis
  },

  SET_ASSESSMENT_DASHBOARD_TAGS(state, tags) {
    state.assessments.dashboardTags = tags
  },

  SET_IS_LIST_MINIMIZED(state, isMinimized) {
    state.assessments.isListMinimized = isMinimized
  },
}
