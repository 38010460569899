export default {
  SET_MYCONTROLS_STATUSES(
    state,
    {
      operationalStatuses,
      implementationStatuses,
      evidenceStatuses,
      evidenceFileStatuses,
    }
  ) {
    state.mycontrols.operationalStatuses = operationalStatuses
    state.mycontrols.implementationStatuses = implementationStatuses
    state.mycontrols.evidenceStatuses = evidenceStatuses
    state.mycontrols.evidenceFileStatuses = evidenceFileStatuses
  },

  SET_MYCONTROLS_FRAMEWORKS(state, frameworks) {
    state.mycontrols.frameworks = frameworks
  },

  SET_MYCONTROLS_DOMAINS(state, domains) {
    state.mycontrols.domains = domains
  },

  SET_MYCONTROLS_CONTROL_GROUPS_DOMAINS(state, domains) {
    state.mycontrols.controlsDomains = domains
  },

  SET_MYCONTROLS_CONTROL_GROUPS(state, info) {
    state.mycontrols.controlGroups = info
    state.mycontrols.allControlGroups = info.data.reduce((acc, curr) => {
      acc.push(curr)
      acc = acc.concat(curr.sub_controls)
      return acc
    }, [])
  },

  SET_MYCONTROLS_CONTROL_GROUPS_TAGS(state, tags) {
    state.mycontrols.controlGroupsTags = tags
  },

  SET_MYCONTROLS_EVIDENCE_TAGS(state, tags) {
    state.mycontrols.evidenceTags = tags
  },

  SET_MYCONTROLS_EVIDENCE(state, info) {
    state.mycontrols.evidence = info
  },

  SET_MYCONTROLS_CURRENT_EVIDENCE(state, evidence) {
    state.mycontrols.currentEvidence.evidence = evidence
  },

  SET_MYCONTROLS_FRAMEWORK_VERSIONS(state, versions) {
    state.mycontrols.currentFramework.versions = versions
  },

  SET_MYCONTROLS_FRAMEWORK_CONTROLS(state, frameworkControls) {
    state.mycontrols.frameworkControls = frameworkControls
  },

  SET_MYCONTROLS_CURRENT_FRAMEWORK(state, framework) {
    state.mycontrols.currentFramework.framework = framework
  },

  SET_MYCONTROLS_CURRENT_FRAMEWORK_CONTROL(state, control) {
    state.mycontrols.currentFrameworkControl.control = control
  },

  SET_MYCONTROLS_CURRENT_FRAMEWORK_CONTROL_CONTROL_GROUPS(
    state,
    controlGroups
  ) {
    state.mycontrols.currentFrameworkControl.controlGroups = controlGroups
  },

  SET_MYCONTROLS_CURRENT_FRAMEWORK_CONTROLS(state, controls) {
    state.mycontrols.currentFramework.controls.data = controls
  },

  SET_MYCONTROLS_CONTROL_GROUP_FILTER(state, { key, val }) {
    state.mycontrols.filters.controlGroups[key] = val
  },

  SET_MYCONTROLS_FRAMEWORK_CONTROL_FILTER(state, { key, val }) {
    state.mycontrols.filters.frameworkControls[key] = val
  },

  SET_MYCONTROLS_TASKS_FILTER(state, { key, val }) {
    state.mycontrols.filters.tasks[key] = val
  },

  SET_MYCONTROLS_EVENTS_FILTER(state, { key, val }) {
    state.mycontrols.filters.events[key] = val
  },

  SET_MYCONTROLS_RISKS_FILTER(state, { key, val }) {
    state.mycontrols.filters.risks[key] = val
  },

  SET_MYCONTROLS_EVIDENCE_FILTER(state, { key, val }) {
    state.mycontrols.filters.evidence[key] = val
  },

  SET_MYCONTROLS_DASHBOARD_FILTER(state, { key, val }) {
    state.mycontrols.filters.dashboard[key] = val
  },

  SET_MYCONTROLS_TASKS_SORTING(state, { key, val }) {
    state.mycontrols.sorting.tasks = {}
    state.mycontrols.sorting.tasks[key] = val
  },

  SET_MYCONTROLS_EVENTS_SORTING(state, { key, val }) {
    state.mycontrols.sorting.events = {}
    state.mycontrols.sorting.events[key] = val
  },

  CLEAR_MYCONTROLS_CURRENT_CONTROL(state) {
    state.mycontrols.currentControl = {
      changeLog: [],
      collaborators: [],
      comments: [],
      control: {},
      events: [],
      evidence: [],
      mappedControls: [],
      owners: [],
      policies: [],
      risks: [],
      scopes: [],
      subControls: [],
      tags: [],
      tasks: [],
    }
  },

  SET_MYCONTROLS_CURRENT_CONTROL_OWNERS(state, owners) {
    state.mycontrols.currentControl.owners = owners
  },

  SET_MYCONTROLS_CURRENT_CONTROL_COLLABORATORS(state, collaborators) {
    state.mycontrols.currentControl.collaborators = collaborators
  },

  SET_MYCONTROLS_CURRENT_CONTROL(
    state,
    { controlGroup, mappedControls, owners, collaborators }
  ) {
    state.mycontrols.currentControl.control = controlGroup
    state.mycontrols.currentControl.mappedControls = mappedControls
    state.mycontrols.currentControl.owners = owners
    state.mycontrols.currentControl.collaborators = collaborators
  },
  SET_MYCONTROLS_CURRENT_CONTROL_POLICIES(state, policies) {
    state.mycontrols.currentControl.policies = policies
  },

  SET_MYCONTROLS_CURRENT_CONTROL_EVIDENCE(state, evidence) {
    state.mycontrols.currentControl.evidence = evidence
  },

  SET_MYCONTROLS_CURRENT_CONTROL_TAGS(state, tags) {
    state.mycontrols.currentControl.tags = tags
  },

  SET_MYCONTROLS_CURRENT_CONTROL_MAPPED_CONTROLS(state, mappedControls) {
    state.mycontrols.currentControl.mappedControls = mappedControls
  },

  SET_MYCONTROLS_CURRENT_CONTROL_SCOPES(state, scopes) {
    state.mycontrols.currentControl.scopes = scopes
  },

  SET_MYCONTROLS_CURRENT_CONTROL_SUB_CONTROLS(state, subControls) {
    state.mycontrols.currentControl.subControls = subControls
  },

  SET_MYCONTROLS_CURRENT_CONTROL_TASKS(state, tasks) {
    state.mycontrols.currentControl.tasks = tasks
  },

  SET_MYCONTROLS_CURRENT_EVIDENCE_TASKS(state, tasks) {
    state.mycontrols.currentEvidence.tasks = tasks
  },

  SET_MYCONTROLS_CURRENT_CONTROL_EVENTS(state, events) {
    state.mycontrols.currentControl.events = events
  },

  SET_MYCONTROLS_CURRENT_EVIDENCE_EVENTS(state, events) {
    state.mycontrols.currentEvidence.events = events
  },

  SET_MYCONTROLS_CURRENT_EVIDENCE_CONTROLS(state, controls) {
    state.mycontrols.currentEvidence.controls = controls
  },

  SET_MYCONTROLS_CURRENT_EVIDENCE_TYPICAL_EVIDENCE_FILES(state, files) {
    state.mycontrols.currentEvidence.typicalEvidenceFiles = files
  },

  SET_MYCONTROLS_CURRENT_CONTROL_RISK_MAPPINGS(state, risks) {
    state.mycontrols.currentControl.risks = risks
  },

  SET_MYCONTROLS_CURRENT_CONTROL_CHANGE_LOG(state, changeLog) {
    state.mycontrols.currentControl.changeLog = changeLog
  },

  SET_MYCONTROLS_FRAMEWORK_CURRENT_CONTROL_CHANGE_LOG(state, changeLog) {
    state.mycontrols.currentFrameworkControl.changeLog = changeLog
  },

  CLEAR_MYCONTROLS_CURRENT_EVIDENCE(state) {
    state.mycontrols.currentEvidence = {
      changeLog: [],
      collaborators: [],
      comments: [],
      controls: [],
      events: [],
      evidence: {},
      files: [],
      links: [],
      owners: [],
      tags: [],
      tasks: [],
    }
  },

  SET_MYCONTROLS_CURRENT_EVIDENCE_CHANGE_LOG(state, changeLog) {
    state.mycontrols.currentEvidence.changeLog = changeLog
  },

  SET_MYCONTROLS_CURRENT_EVIDENCE_FILES(state, linkedFiles) {
    state.mycontrols.currentEvidence.files = linkedFiles
  },

  SET_MYCONTROLS_CURRENT_EVIDENCE_ASIGNEES(state, assignees) {
    state.mycontrols.currentEvidence.assignees = assignees
  },

  SET_MYCONTROLS_CURRENT_EVIDENCE_COLLABORATORS(state, collaborators) {
    state.mycontrols.currentEvidence.collaborators = collaborators
  },

  SET_MYCONTROLS_CURRENT_CONTROL_COMMENTS(state, comments) {
    state.mycontrols.currentControl = {
      ...state.mycontrols.currentControl,
      comments,
    }
  },

  SET_MYCONTROLS_CURRENT_EVIDENCE_COMMENTS(state, comments) {
    state.mycontrols.currentEvidence = {
      ...state.mycontrols.currentEvidence,
      comments,
    }
  },

  SET_MYCONTROLS_CURRENT_EVIDENCE_TAGS(state, tags) {
    state.mycontrols.currentEvidence.tags = tags
  },

  CLEAR_MYCONTROLS_CURRENT_FRAMEWORK(state) {
    state.mycontrols.currentFramework = {
      framework: {
        id: null,
      },
      controls: {
        currentPage: 1,
        data: [],
        numberPages: 1,
        perPage: 500,
        totalCount: 0,
      },
    }
  },

  CLEAR_MYCONTROLS_CURRENT_FRAMEWORK_CONTROL(state) {
    state.mycontrols.currentFrameworkControl = {
      control: {},
      changeLog: [],
      controlGroups: [],
    }
  },

  SET_MYCONTROLS_DASHBOARD_DONUT_DATA(state, data) {
    state.mycontrols.dashboard.domainCounts = {}
    Object.assign(state.mycontrols.dashboard.domainCounts, data)
  },

  SET_MYCONTROLS_DASHBOARD_CONTROL_GROUPS(state, controlGroups) {
    state.mycontrols.dashboard.controlGroups = controlGroups.data
  },

  SET_MYCONTROLS_DASHBOARD_EVIDENCE_DATA(state, evidence) {
    state.mycontrols.dashboard.evidence = evidence.data
  },

  SET_MYCONTROLS_DASHBOARD_TAGS(state, tags) {
    state.mycontrols.dashboard.allTags = tags
  },

  SET_MYCONTROLS_DASHBOARD_STATUS_COUNTS(
    state,
    { dashboardControlGroupStatusCounts, dashboardEvidenceStatusCounts }
  ) {
    state.mycontrols.dashboard.controlStatusCounts =
      dashboardControlGroupStatusCounts
    state.mycontrols.dashboard.evidenceStatusCounts =
      dashboardEvidenceStatusCounts
  },

  SET_MYCONTROLS_CONTROL_GROUPS_STATUS_COUNTS_OVER_TIME(state, data) {
    state.mycontrols.dashboard.controlGroupsStatusCountsOverTime = data
  },

  SET_MYCONTROLS_LAST_SELECTED_PROJECT(state, project) {
    state.mycontrols.lastSelectedProject = project
  },

  SET_MYCONTROLS_LAST_SELECTED_PROJECT_SECTION(state, section) {
    state.mycontrols.lastSelectedProjectSection = section
  },

  SET_MYCONTROLS_FILTERED_VIEWS(state, views) {
    state.mycontrols.filteredViews = views
  },

  SET_MYCONTROLS_CURRENT_VIEW_ID(state, id) {
    state.mycontrols.currentViewId = id
  },

  RESET_MYCONTROLS_FILTERS(state) {
    resetControlGroupFilters(state)
    resetDashboardFilters(state)
    resetEventFilters(state)
    resetEvidenceFilters(state)
    resetFrameworkControlFilters(state)
    resetTaskFilters(state)
    resetRiskFilters(state)
  },

  RESET_CONTROL_GROUP_FILTERS(state, clearAll = false) {
    resetControlGroupFilters(state, clearAll)
  },

  RESET_MYCONTROLS_DASHBOARD_FILTERS(state, clearAll = false) {
    resetDashboardFilters(state, clearAll)
  },

  RESET_EVENTS_FILTERS(state) {
    resetEventFilters(state)
  },

  RESET_EVIDENCE_FILTERS(state) {
    resetEvidenceFilters(state)
  },

  RESET_FRAMEWORK_CONTROLS_FILTERS(state, clearAll = false) {
    resetFrameworkControlFilters(state, clearAll)
  },

  RESET_TASKS_FILTERS(state) {
    resetTaskFilters(state)
  },

  RESET_RISKS_FILTERS(state) {
    resetRiskFilters(state)
  },
}

function resetControlGroupFilters(state, clearAll = false) {
  state.mycontrols.filters.controlGroups = {
    // only show applicable control groups by default
    applicability: clearAll ? [] : [true],
    implementationStatus: [],
    operationalStatus: [],
    domain: [],
    evidence: [],
    framework: [],
    scope: [],
    owner: [],
    tag: [],
    status: [],
  }
}

function resetDashboardFilters(state, clearAll = false) {
  state.mycontrols.filters.dashboard = {
    // only show applicable control groups/evidence by default
    applicability: clearAll ? [] : [true],
    domain: [],
    framework: [],
    scope: [],
    owner: [],
    tag: [],
    // date_start: null,
    // date_end: moment().add(14, 'day'),
  }
}

function resetEventFilters(state) {
  state.mycontrols.filters.events = {
    owner: [],
    status: [null, 'on_track', 'behind', 'overdue', 'not_started'],
  }
}

function resetEvidenceFilters(state) {
  state.mycontrols.filters.evidence = {
    // only show applicable evidence by default
    applicability: [true],
    status: [],
    domain: [],
    framework: [],
    scope: [],
    assignee: [],
    tag: [],
    expiration: [],
  }
}

function resetFrameworkControlFilters(state, clearAll = false) {
  state.mycontrols.filters.frameworkControls = {
    // only show applicable controls by default
    applicability: clearAll ? [] : [true],
    domain: [],
    scope: [],
    controlGroup: [],
  }
}

function resetTaskFilters(state) {
  state.mycontrols.filters.tasks = {
    criticality: [],
    owner: [],
    status: ['on_track', 'behind', 'overdue', 'not_started'],
    tags: [],
  }
}

function resetRiskFilters(state) {
  state.mycontrols.filters.risks = {
    riskScore: [],
    decision: [],
    status: ['not_started', 'in_progress', 'behind_schedule', 'on_track'],
  }
}
