export default {
  achievement: 'solid fa-trophy',
  add: 'solid fa-plus',
  addUser: 'solid fa-user-plus',
  applied: 'solid fa-circle-check',
  archive: 'solid fa-box-archive',
  assessments: 'solid fa-square-list',
  assessmentControls: 'solid fa-intersection',
  attach: 'solid fa-paperclip-vertical',
  automations: 'solid fa-robot',
  clone: 'solid fa-clone',
  closeRemove: 'solid fa-xmark',
  collapseVertical: 'solid fa-compress',
  comment: 'solid fa-comments',
  complete: 'solid fa-circle-check',
  complianceCalendar: 'solid fa-calendar-days',
  controls: 'light fa-solar-system',
  copy: 'solid fa-copy',
  criticality: 'solid fa-circle-exclamation',
  danger: 'solid fa-triangle-exclamation',
  date: 'solid fa-calendar-days',
  delete: 'solid fa-trash-can',
  download: 'solid fa-download',
  dropdownCaret: 'solid fa-caret-down',
  dropupCaret: 'solid fa-caret-right',
  edit: 'solid fa-pen-to-square',
  execute: 'solid fa-function',
  expandVertical: 'solid fa-expand',
  export: 'solid fa-file-export',
  file: 'solid fa-file-lines',
  filter: 'solid fa-filter',
  folder: 'solid fa-folder',
  hide: 'solid fa-eye-slash',
  home: 'solid fa-house',
  horizontalCollapse: 'solid fa-chevron-left',
  horizontalExpand: 'solid fa-chevron-right',
  integrations: 'solid fa-webhook',
  inventories: 'solid fa-briefcase',
  keys: 'solid fa-key',
  jira: 'bold fa-atlassian',
  link: 'solid fa-link',
  lock: 'regular fa-lock',
  managementReview: 'solid fa-check-double',
  map: 'solid fa-map',
  moreFunction: 'regular fa-ellipsis',
  moreInfo: 'solid fa-circle-info',
  move: 'solid fa-arrow-down-arrow-up',
  notifications: 'solid fa-bell',
  orgSettings: 'solid fa-lock-keyhole',
  penetrationTesting: 'solid fa-user-secret',
  policies: 'solid fa-scroll',
  projectManagement: 'solid fa-clipboard-list',
  questionnaires: 'solid fa-poll-people',
  rearrange: 'solid fa-grip-dots-vertical',
  reporting: 'solid fa-square-poll-vertical',
  restore: 'solid fa-trash-undo',
  reset: 'solid fa-arrows-rotate',
  riskRegisters: 'solid fa-hexagon-exclamation',
  scope: 'solid fa-sitemap',
  scoring: 'solid fa-analytics',
  search: 'solid fa-magnifying-glass',
  selected: 'solid fa-square-check',
  selfAssessments: 'solid fa-table',
  send: 'solid fa-paper-plane',
  settings: 'solid fa-gear',
  show: 'solid fa-eye',
  sort: 'solid fa-sort',
  splitScreen: 'solid fa-table-columns',
  subtasks: 'solid fa-list-tree',
  tags: 'solid fa-tags',
  tasks: 'solid fa-list-ul',
  unlink: 'solid fa-link-slash',
  unlock: 'solid fa-lock-open',
  unselected: 'regular fa-square',
  uploadImport: 'solid fa-cloud-arrow-up',
  user: 'solid fa-user',
  users: 'solid fa-users',
  userPlus: 'solid fa-user-plus',
  vendorManagement: 'solid fa-address-book',
  versionHistory: 'solid fa-clock-rotate-left',
  vulnerabilitiesManagement: 'solid fa-bug',
  warning: 'solid fa-triangle-exclamation',
}
