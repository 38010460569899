export default {
  basic: {},

  addUser: {
    type: 'tertiary',
    prefixIcon: 'addUser',
  },

  cancel: {
    type: 'tertiary',
    text: 'Cancel',
  },

  clear: {
    type: 'tertiary',
    text: 'Clear',
    postfixIcon: 'closeRemove',
    danger: true,
  },

  comment: {
    type: 'primary',
    text: 'Add Comment',
  },

  create: {
    type: 'primary',
    prefixIcon: 'add',
    text: 'Create',
    size: 'medium',
  },

  createMultiSelect: {
    type: 'primary',
    prefixIcon: 'add',
    text: 'Create New',
    postfixIcon: 'dropdownCaret',
    size: 'medium',
  },

  delete: {
    type: 'primary',
    prefixIcon: 'delete',
    text: 'Delete',
    danger: true,
  },

  save: {
    type: 'primary',
    text: 'Save',
  },

  cancel: {
    type: 'tertiary',
    text: 'Cancel',
  },

  dropdown: {
    postfixIcon: 'dropdownCaret',
    type: 'secondary',
  },

  icon: {
    type: 'secondary',
    shape: 'circle',
  },

  save: {
    type: 'primary',
    text: 'Save',
  },

  sendMail: {
    type: 'primary',
    prefixIcon: 'send',
    text: 'Send',
  },

  // relationships
  addRelationship: {
    type: 'secondary',
    prefixIcon: 'add',
  },

  removeRelationship: {
    type: 'secondary',
    prefixIcon: 'closeRemove',
    danger: true,
  },

  preview: {
    type: 'secondary',
    prefixIcon: 'show',
    text: 'Preview',
  },

  select: {
    type: 'secondary',
    prefixIcon: 'selected',
    size: 'medium',
  },

  deselect: {
    type: 'secondary',
    prefixIcon: 'unselected',
    size: 'medium',
  },

  collapse: {
    type: 'secondary',
    prefixIcon: 'collapseVertical',
    size: 'medium',
  },

  expand: {
    type: 'secondary',
    prefixIcon: 'expandVertical',
    size: 'medium',
  },
}
